










































































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator';
import { Product } from '@/api/braendz';

interface FeatureItem {
  description: string,
  type: 'Included' | 'Excluded' | 'Limitation' 
}

@Component({
  components: {
  }
})
export default class ProductCard extends Vue {
  
  // Member:
  @Prop({ required: true })
  public product!: Product;

  @Prop({ required: false })
  public activeProduct?: Product | null;

  // Getter:
  public get subscriptionTransformation(): 'Subscribe' | 'Downgrade' | 'Same' | 'Upgrade' {
    return this.$braendz.checkSubscriptionTransformation(this.activeProduct, this.product);
  }

  public get featureItems(): FeatureItem[] {

    if(this.product.key === 'anonymous') {
      return [
        { type: 'Included', description: 'Similarity Search' },
        { type: 'Limitation', description: 'Many hidden Results' },
        { type: 'Included', description: 'Image Recognition' },
        { type: 'Included', description: 'Brand status filterable' },
        { type: 'Included', description: 'Brand type filterable' },
        { type: 'Included', description: 'Country filterable' },
        { type: 'Included', description: 'Classifications filterable' }
      ];
    }
    else if(this.product.key === 'registered') {
      return [
        { type: 'Included', description: 'Similarity Search' },
        { type: 'Limitation', description: '20 Search Requests' },
        { type: 'Included', description: 'Image Recognition' },
        { type: 'Included', description: 'Brand status filterable' },
        { type: 'Included', description: 'Brand type filterable' },
        { type: 'Included', description: 'Country filterable' },
        { type: 'Included', description: 'Classifications filterable' },
        { type: 'Included', description: 'View trademark details' },
        { type: 'Included', description: 'View owner information' },
        { type: 'Included', description: 'View representative information' },
        { type: 'Included', description: 'View process information' }
      ];
    }
    else if(this.product.key === 'standard') {
      return [
        { type: 'Included', description: 'Search brands in one predefined country' },
        { type: 'Included', description: 'Image Recognition' },
        { type: 'Included', description: 'Brand status filterable' },
        { type: 'Included', description: 'Brand type filterable' },
        { type: 'Included', description: 'Country filterable' },
        { type: 'Included', description: 'Classifications filterable' },
        { type: 'Included', description: 'Opposition period filterable' },
        { type: 'Included', description: 'View trademark details' },
        { type: 'Included', description: 'View owner information' },
        { type: 'Included', description: 'View representative information' },
        { type: 'Included', description: 'View process information' },
        { type: 'Included', description: 'Save Searches' },
        { type: 'Included', description: 'Select your own brands' },
        { type: 'Included', description: 'Automatic Trademark Monitoring' }
      ];
    }
    else if(this.product.key === 'premium') {
      return [];
    }
    else if(this.product.key === 'ultimate') {
      return [];
    }
    
    return [];
  }

  // Lifecycle Methods:

}
