
























































































import Vue from 'vue'
import { Component } from 'vue-property-decorator';

import ProductCard from '@/components/Products/ProductCard.vue';
import { RobotOption, TwitterCardOption } from '@/models/MetaTags';
import { getLocalesString } from '@/models/Locale';
import { BusyList, BusyObject } from '@/models/Busy';
import { Product, ProductsService, Subscription } from '@/api/braendz';
import { AccountInfo } from '@azure/msal-browser';

@Component({
  components: {
    ProductCard
  },
  metaInfo() {
    return {
      title: this.$i18n.t("insights.title").toString(),
      meta: [
        // Search Engines:
        { name: 'title', content: this.$i18n.t("insights.metaTags.title").toString() },
        { name: 'description', content: this.$i18n.t("insights.metaTags.description").toString() },
        { name: 'keywords', content: this.$i18n.t("metaTags.keywords").toString() },
        { name: 'author', content: this.$i18n.t("metaTags.author").toString() },
        { name: 'language', content: this.$i18n.locale },
        { name: 'robots', content: [RobotOption.All].join(',') },

        // Open Graph: Facebook, Instagram, WhatsApp, LinkedIn, Xing, Twitter:
        { property: 'og:type', content: "website" },
        { property: 'og:title', content: this.$i18n.t("insights.metaTags.title").toString() },
        { property: 'og:description', content: this.$i18n.t("insights.metaTags.description").toString() },
        { property: 'og:image', content: `${window.location.origin}${require('@/assets/logos/braendz-logo-tm-blue.png')}` },
        { property: 'og:locale', content: this.$i18n.locale },
        { property: 'og:locale:alternate', content: getLocalesString(',') },
        { property: 'og:site_name', content: this.$i18n.t("metaTags.title").toString()},

        // Twitter:
        { property: 'twitter:card', content: TwitterCardOption.SummaryLargeImage },
        { property: 'twitter:site', content: `@${process.env.VUE_APP_TWITTER_ACCOUNT}` }
      ]
    };
  }
})
export default class Pricing extends Vue {

  // Member:
  public products = new BusyList<Product>();

  // Getter:
  public get userAccount(): AccountInfo | null {
    return this.$store.state.userAccount;
  }

  public get activeSubscription(): BusyObject<Subscription> {
      return this.$store.state.activeSubscription;
  }

  // Lifecycle Methods:
  public mounted() {
      this.refreshProducts();
  }

  // Methods
  public async refreshProducts() {
      await this.products.load(async () => {
        return await ProductsService.getProducts(false);
      });
  }
}
